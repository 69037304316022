import { IUserGroup } from './../../../../models/user-group.model';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUser } from 'src/app/models/user.model';
import { ReplaySubject } from 'rxjs';
import { UserGroupService } from 'src/app/services/user-group.service';
import { AuthService } from 'src/app/services/auth.service';

export interface IUserDialog {
  title?: string;
  mode: 'add' | 'edit' | 'deactivate' | 'activate';
  user?: IUser;
}

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) {
      return null;
    }

    const password = control.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const isLengthValid = password.length >= 8;

    const isValid = hasUpperCase && hasLowerCase && hasSpecialChar && hasNumber && isLengthValid;

    return isValid ? null : { 'passwordStrength': true };
  };
}

@Component({
  selector: 'dhl-users-dialog',
  templateUrl: './users-dialog.component.html',
  styleUrls: ['./users-dialog.component.scss']
})
export class UsersDialogComponent implements OnInit {
  userForm: UntypedFormGroup;
  isFormEnabled: boolean = true;
  hidePassword: boolean = true;
  selected: 'N';
  groups: Array<IUserGroup>;
  filteredGroups: ReplaySubject<Array<IUserGroup>> = new ReplaySubject<Array<IUserGroup>>(1);

  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<UsersDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IUserDialog, private groupService: UserGroupService, private authService: AuthService) { }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      userId: new UntypedFormControl(this.data.user?.PI_USER_ID, [Validators.required, Validators.email]),
      userName: new UntypedFormControl(this.data.user?.PI_USER_NAME, [Validators.required]),
      password: new UntypedFormControl(this.data.user?.PI_USER_PASS, 
        this.data.mode === 'add'
          ? [Validators.required, passwordValidator()]
          : [passwordValidator()]
      ),
      isAdmin: new UntypedFormControl(this.data.user?.PI_USER_IS_ADMIN || 'N', [Validators.required]),
      groupList: new UntypedFormControl(this.data.user?.GRP_LIST && this.data.user?.GRP_LIST.toString().split(',').map(Number)),
      groupFilter: new UntypedFormControl('')
    });

    this.getGroups();
    
    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.userForm.disable();
    }
  }

  closeDialog(user: IUser) {
    this.dialogRef.close(user);
  }

  enableForm() {
    this.isFormEnabled = true;
    this.userForm.get('userName').enable();
    this.userForm.get('password').enable();
    this.userForm.get('isAdmin').enable();
    this.userForm.get('groupList').enable();
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.userForm.value;
    let user: IUser = null;

    if (this.data.mode === 'add') {
      user = {
        PI_USER_ID: formValue.userId,
        PI_USER_NAME: formValue.userName,
        PI_USER_PASS: encodeURIComponent(formValue.password),
        PI_USER_IS_ADMIN: formValue.isAdmin,
        PI_USER_IS_ACTIVE: 'Y',
        GRP_LIST: formValue.groupList,
        USER_LOGGED: this.authService.userId
      }
    } else if (this.data.mode === 'edit') {
      user = {
        PI_USER_ID: this.data.user.PI_USER_ID,
        PI_USER_NAME: formValue.userName,
        PI_USER_PASS: formValue.password ? encodeURIComponent(formValue.password) : null,
        PI_USER_IS_ADMIN: formValue.isAdmin,
        PI_USER_IS_ACTIVE: 'Y',
        GRP_LIST: formValue.groupList,
        USER_LOGGED: this.authService.userId
      }
    } else if (this.data.mode === 'deactivate') {
      user = {
        PI_USER_ID: this.data.user.PI_USER_ID,
        PI_USER_NAME: null,
        PI_USER_PASS: null,
        PI_USER_IS_ADMIN: null,
        PI_USER_IS_ACTIVE: 'N',
        GRP_LIST: null,
        USER_LOGGED: this.authService.userId
      }
    } else if (this.data.mode === 'activate') {
      user = {
        PI_USER_ID: this.data.user.PI_USER_ID,
        PI_USER_NAME: null,
        PI_USER_PASS: null,
        PI_USER_IS_ADMIN: null,
        PI_USER_IS_ACTIVE: 'Y',
        GRP_LIST: null,
        USER_LOGGED: this.authService.userId
      }
    }

    this.closeDialog(user);
  }

  getGroups(): void {
    this.groupService.getUserGroup().subscribe((groups) => {
      this.groups = groups;
      this.filteredGroups.next(groups)
      if (this.data.mode == 'add' || this.data.mode == 'edit') {
        this.userForm.get('groupFilter').valueChanges.subscribe(() => {
          this.filterGroups();
        });
      }
    });
  }

  filterGroups(): void {
    const filterValue = this.userForm.get('groupFilter').value.toLowerCase();
    this.filteredGroups.next(this.groups.filter((group) => group.GRP_NAME.toLowerCase().includes(filterValue)));
  }

  getEmailErrorMessage(): string {
    if (this.userForm.get('userId').hasError('required')) {
      return 'You must enter an email';
    } else if (this.userForm.get('userId').hasError('email')) {
      return 'Not a valid email';
    } else {
      return '';
    }
  }

  areInputsValid(): boolean {
    const isUserIdInvalid = this.userForm.get('userId').invalid;
    const isUserNameInvalid = this.userForm.get('userName').invalid;
    const isPasswordInvalid = this.userForm.get('password').invalid;
    const isAdminInvalid = this.userForm.get('isAdmin').invalid;
    return isUserIdInvalid || isUserNameInvalid || isPasswordInvalid || isAdminInvalid;
  }
}
