<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="userForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode !== 'deactivate' && data.mode !== 'activate'">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" name="userId" formControlName="userId" required>
        <mat-error *ngIf="userForm.get('userId').invalid">{{getEmailErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'deactivate' && data.mode !== 'activate'">
      <mat-form-field>
        <mat-label>User Name</mat-label>
        <input matInput type="text" name="userName" formControlName="userName" required>
        <mat-error *ngIf="userForm.get('userName').invalid">You must enter an user name</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'deactivate' && data.mode !== 'activate'">
      <mat-form-field>
        <mat-label>Role</mat-label>
        <mat-select name="isAdmin" formControlName="isAdmin" required>
          <mat-option value="N">
            Standard
          </mat-option>
          <mat-option value="Y">
            Admin
          </mat-option>
        </mat-select>
        <mat-error *ngIf="userForm.get('isAdmin').invalid">You must select a role</mat-error>
      </mat-form-field>      
    </div>    
    <div class="form-group" *ngIf="data.mode !== 'deactivate' && data.mode !== 'activate'">
      <mat-form-field color="accent">
        <mat-label>Groups</mat-label>
        <mat-select name="groupList" formControlName="groupList" multiple>
          <mat-option>
            <ngx-mat-select-search formControlName="groupFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let group of filteredGroups | async" [value]="group.GRP_ID">
            {{group.GRP_NAME}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'deactivate' && data.mode !== 'activate'">
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" name="password" formControlName="password" [required]="data.mode === 'add'">
        <button [disabled]="!userForm.get('password').enabled" type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
          <mat-icon color="primary">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="userForm.get('password').hasError('required')">You must enter a password</mat-error>
        <mat-error *ngIf="userForm.get('password').hasError('passwordStrength')">
          Password must be at least 8 characters long and include uppercase, lowercase, number, and special characters.
        </mat-error>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode === 'deactivate'">Do you really wish to deactivate the user {{data.user.PI_USER_NAME}}?</p>
    <p *ngIf="data.mode === 'activate'">Do you really wish to reactivate the user {{data.user.PI_USER_NAME}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'add'" [disabled]="areInputsValid()">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'deactivate'">Deactivate</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'activate'">Activate</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="areInputsValid()">Ok</button>
    </div>
  </form>
</div>