import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{

  constructor(
    private authService: AuthService
  ) { }
  
  ngOnInit(): void {    
    const token = localStorage.getItem('access_token');

    if (!token) return;

    this.authService.getCredentials(token).subscribe((user) => {
      this.authService.userId = user.USER_ID.toUpperCase();
      this.authService.userName = user.USER_NAME;
      this.authService.userAllowedUrls = user.PAG_URL.split(', ');
      this.authService.isUserAdmin = user.USER_IS_ADMIN === 'Y';
      this.authService.hasLoadedInfo = true;
    });
  }
}
